<template>
  <div>
    
  
  <div class="conditionBox">
    <el-form ref="form" :model="clueDto">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="租房方式" prop="saletype"  >
            <el-select v-model="clueDto.rentalType" placeholder="请选择"  clearable  class="select-r">
              <el-option
                  v-for="item in rentalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地区" prop="saletype" >
            <el-select v-model="clueDto.market" placeholder="请选择"  clearable  class="select-r">
              <el-option
                  v-for="item in cityArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="标题"  >
            <el-input v-model="clueDto.title" placeholder="标题" class="input-r" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址">
            <el-input v-model="clueDto.address" placeholder="地址" class="input-r" clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
            <el-form-item label="预算金额" >
              <el-input v-model="clueDto.budgetMin" placeholder="最小金额" class="input-r" clearable></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至</span>
            <el-input v-model="clueDto.budgetMax" placeholder="最大金额" class="input-r" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="手机号" >
            <el-input v-model="clueDto.phoneNumber" placeholder="手机号" class="input-r" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="微信号">
            <el-input v-model="clueDto.wx" placeholder="微信号" class="input-r" clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="button-r">
      <el-button type="primary" @click="getClue()"  icon="el-icon-search" size="small" plain>查询</el-button>
      <el-button type="info"    @click="resetting()" icon="el-icon-refresh" size="small"  plain>重置</el-button>
    </div>

  </div>

  <div class="table-r">
    <div class="buttonBox">
      <el-button style="font-size: 13px;" v-if="checkStatus==0" type="primary"  @click="add()" icon="el-icon-plus" size="small" plain>新增线索</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==0" type="success" @click="grounding(0)" icon="el-icon-search" size="small" plain>查询已上架({{upSum}})</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==0" type="danger" @click="grounding(1)" icon="el-icon-search" size="small" plain>查询未上架({{downSum}})</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==1" type="success" @click="editGrounding(0)" icon="el-icon-upload2" size="small" plain>上架线索</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==1" type="danger" @click="editGrounding(1)" icon="el-icon-download" size="small" plain>下架线索</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==0 " type="success"  @click="edit()" icon="el-icon-edit" size="small" plain>编辑</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==0 " type="danger"   @click="Delete()" icon="el-icon-delete" size="small" plain>删除</el-button>
      <el-button style="font-size: 13px;" v-if="checkStatus==1 " type="danger"   @click="deleteBatch()" icon="el-icon-delete" size="small" plain>批量删除</el-button>
    </div>
    <vxe-table
      border
      show-header-overflow
      show-overflow
      :row-config="{isHover: true,height: 40,isCurrent: true,}"
      height="500px"
      :data="list"
      :loading="loading"
      :loading-config="{icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...'}"
      stripe
      round
      @current-change="currentChangeEvent"
      :seq-config="{startIndex: (currentPage - 1) * pageSize}"
      ref="xTable"
      align="center"
      class="mytable-scrollbar"
      style="width: 100%;"
      :cell-style="cellStyle"
      @checkbox-all="selectAllEvent"
      @checkbox-change="selectChangeEvent"
    >
      <vxe-column type="checkbox" width="60"></vxe-column>
      <vxe-column type="seq" title="序号"  width="60"></vxe-column>
      <vxe-column field="title" title="标题"   width="230px"></vxe-column>
      <vxe-column field="rentalType" title="租房方式"  :formatter="formatterRental"></vxe-column>
      <vxe-column field="address" title="地址"   :formatter="formatterAddress"  width="180px"></vxe-column>
      <vxe-column field="saletype" title="户型"     :formatter="formatterSaletype" width="130px"> </vxe-column>
      <vxe-column field="budget" title="预算"  :formatter="formatterBudget" width="130px"> </vxe-column>
      <vxe-column field="phoneNumber" title="手机号" :formatter="formatterPhoneNumber" width="110px"></vxe-column>
      <vxe-column field="wx" title="微信" :formatter="formatterWx"></vxe-column>
      <vxe-column field="price" title="线索价格"  ></vxe-column>
      <vxe-column field="createTime" title="已上架天数"  :formatter="formatterDay"> </vxe-column>
      <vxe-column field="source" title="线索来源"  :formatter="formatterSource"> </vxe-column>
      <vxe-column field="unlockCount" title="解锁次数"  :formatter="formatterSum"></vxe-column>
      <vxe-column field="groundingStatus" title="状态"  :formatter="formatterStatus"> </vxe-column>
      <vxe-column field="remarks" title="备注" ></vxe-column>
    </vxe-table>
    <div style="float: right;">
      <vxe-pager
        background
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :total="totalResult"
        :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
        :page-sizes="pageSizes"
        @page-change="handleCurrentChange"
        align="left"
        size="mini" 

        >
      </vxe-pager>
    </div>
  </div>



  <addClue ref="addClue" ></addClue>
</div>
</template>

<script>
import addClue from './addClue'

export default {
  name: "clueManagement",
  components:{
    addClue
  },
  data: function (){
    return{
      list: null,  //表格数据
      currentPage: 1,     // 当前页
      totalResult: 0,     // 总条数，根据接口获取数据长度(注意：这里不能为空)
      pageSizes: [10, 20, 30, 50],    // 个数选择器（可修改）
      pageSize: 20,       // 默认每页显示的条数（可修改）
      clueDto: {title:null,rentalType:null,address:null,budgetMin:null,budgetMax:null,paymentType:null,phoneNumber:null,wx:null},//搜索框条件
      addOrEdit:0,
      currentRow: 1,  //点击表格获取单格表的数据
      cityArr:[{ label: '武汉市', value: '武汉市' },{ label: '北京市', value: '北京市' },{ label: '上海市', value: '上海市' }],
      paymentOptions:[{ label: '月付', value: '0' },{ label: '季付', value: '1' }],
      rentalOptions: [{ label: '合租', value: '0' },{ label: '整租', value: '1' },{ label: '独栋', value: '2' }],
      statusOptions:[{ label: '上架', value: '0' },{ label: '下架', value: '1' }],
      ids:'',
      upSum:'',
      downSum:'',
      checkStatus:0,
      loading:false,
      user: JSON.parse(sessionStorage.getItem('user')),
      identity:'',
    }
  },
  created(){
    this.loading=true
  },
  mounted:function(){
    this.getClue()
    //绑上刷新数据事件
    this.$bus.$on('refresh', this.getClue)


  },
  beforeDestroy() {
      // 在该组件弃用删除之后，解绑该事件，减轻$bus的负担
      this.$bus.$off('refresh')
  },
  methods:{
    //带条件分页的获取线索列表
    getClue(){
      if(this.clueDto.groundingStatus==undefined){   
        this.clueDto.groundingStatus=0
      }

      this.$axios.get(this.$httpUrl+'/clue/queryPc',
      {
        params:{page:this.currentPage,  pageSize:this.pageSize, title:this.clueDto.title, rentalType:this.clueDto.rentalType, checkInTime:this.clueDto.checkInTime,
        address:this.clueDto.address, budgetMin:this.clueDto.budgetMin,  budgetMax:this.clueDto.budgetMax,  market:this.clueDto.market, 
        phoneNumber:this.clueDto.phoneNumber, wx:this.clueDto.wx,groundingStatus:this.clueDto.groundingStatus,userId:this.user.id,identity:this.user.identity}
      }
      )
      .then(res=>{
        this.list=res.data.data.list
        this.totalResult=res.data.data.total

        this.upSum = res.data.map.up
        this.downSum = res.data.map.down
        this.loading = false

        //默认高亮表格第一行,并获取第一行数据
        if(this.list!=null){
          this.$refs.xTable.setCurrentRow(this.list[0])
        }      
        this.currentRow=this.list[0]
      })},
    add(){
      //打开添加界面
      this.$refs.addClue.toAdd()
    },
    edit(){
      //打开编辑界面
      this.$refs.addClue.toEdit(this.currentRow)
    },
    Delete(){
      //删除操作
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
      .then(() => {
          this.$axios.delete(this.$httpUrl+"/clue/delete/"+this.currentRow.id
      )
      .then(res => {    

        if (res.data.code>=1){
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.currentPage=1
          this.getClue()
        }else{
          this.$message.error('删除失败');
        }
      })}).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    currentChangeEvent({row}){
      //点击获取当前行数据
      this.currentRow = row;

    },
    handleCurrentChange({currentPage,pageSize}){
      //分页控制
      this.currentPage=currentPage
      this.pageSize=pageSize

      this.getClue()
    },
    resetting(){
      //重置按钮
      this.clueDto={}
      this.getClue()
    },
    grounding(e){
      this.clueDto.groundingStatus=e
      this.currentPage=1
      this.getClue()
    },
    cellStyle({ row, column }){
      //大于15天的线索以红色底纹标红
      if (column.field === 'createTime') {
        let groundingDate = this.dateMinus(row.createTime)
        if (groundingDate >= 30) {
          return {
            backgroundColor: '#fbc4c4'
          }
        }else if(groundingDate >=15){
          return {
            backgroundColor: '#FDF6EC'
          }  
        }
      }
    },
    selectAllEvent ({ checked }) {
        //全选复选框后触发的事件
        const records = this.$refs.xTable.getCheckboxRecords()
        //隐藏查询上下架、新增按钮
        this.checkStatus=1

        if(checked==false && records.length==0){
            this.checkStatus=0
            return
        }

        this.ids=''

        records.forEach((element,index) => {
            if(records.length-1==index){
                this.ids = this.ids + element.id
                return
            }
            this.ids = this.ids + element.id+','
        });

    },
    selectChangeEvent ({ checked }) {
        //单击复选框后触发的事件
        const records = this.$refs.xTable.getCheckboxRecords()
        //隐藏查询上下架、新增按钮
        this.checkStatus=1

        if(checked==false && records.length==0){
            this.checkStatus=0
            return
        }

        this.ids=''

        records.forEach((element,index) => {
            if(records.length-1==index){
                this.ids = this.ids + element.id
                return
            }
            this.ids = this.ids + element.id+','
        });

    },
    editGrounding(e){

    var data = [this.ids,e]

    this.$axios.post(this.$httpUrl+"/clue/editGrounding",data)
    .then(res => {
        if(res.data.code==1){
            if(e==0){
                this.$message({message: '上架成功',type: 'success'});
            }else if(e==1){
                this.$message({message: '下架成功',type: 'success'});
            }
            this.checkStatus=0
            this.currentPage=1
            this.getClue()
            
        }else{
            if(e==0){
                this.$message({message: '上架失败',type: 'error'});
            }else if(e==1){
                this.$message({message: '下架失败',type: 'error'});
            }
            this.checkStatus=0
            this.getClue()

        }
    })
    },
    deleteBatch(){
      //删除操作
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
      .then(() => {this.$axios.delete(this.$httpUrl+"/clue/deleteBatch/",{params:{ids:this.ids}})
      .then(res => {    
        if (res.data.code>=1){
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.currentPage=1
          this.getClue()
        }else{
          this.$message.error('删除失败');
        }
      }) }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    formatterDay({cellValue}){

        let groundingDate =  this.dateMinus(cellValue)


        if(groundingDate==0){
          return '1天内'
        }else{
          return groundingDate+'天'
        }

    },
    dateMinus(dateStr){
      const today = new Date();
      const compareDate = new Date(dateStr);

      const oneDay = 24 * 60 * 60 * 1000;

      // 取当天 0 时 0 分 0 秒的时间戳
      const startOfDayToday = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
      const startOfDayCompare = new Date(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate()).getTime();

      const timeDiff = Math.abs(startOfDayCompare - startOfDayToday);

      return Math.floor(timeDiff / oneDay);

    },
    //状态字段格式化
    formatterStatus({ cellValue }){
      if(cellValue==0){
        return '上架'
      }else if(cellValue==1){
        return '下架'
      }
    },
    formatterSource({ cellValue }){
      if(cellValue==0){
        return '闲鱼'
      }else if(cellValue==1){
        return '高德'
      }else if(cellValue==2){
        return '小红书'
      }else if(cellValue==3){
        return '其他'
      }
    },
    //租房方式字段格式化
    formatterRental({ cellValue }) {
      let item = this.rentalOptions.find(item => cellValue == item.value)
      return item ? item.label : ''
    },
    //户型字段格式化
    formatterSaletype({ row }) {
      return row.chamber+'室'+row.office+'厅'+row.kitchen+'厨'+row.toilet+'卫'
    },
    //预算字段格式化
    formatterBudget({ row }) {
      return row.budgetMin+'-'+row.budgetMax+'元'
    },
    //地址字段格式化
    formatterAddress({ cellValue }){
      return cellValue.replace(/\s+/g, ''); // 使用正则表达式将所有连续的空格替换为空字符串
    },
    formatterSum({ cellValue }){
      return cellValue+'次'
    },
    formatterPhoneNumber({cellValue}){
      if(cellValue==null || cellValue==''){
        return '无'
      }
      return cellValue
    },
    formatterWx({cellValue}){
      if(cellValue==null || cellValue==''){
        return '无'
      }
      return cellValue
    },
  }

}
</script>

<style  scoped>

.input-r{
    width: 60%;
    float: right;
    
  }
  .select-r {
    width: 60%;
    float: right;
  }
  .conditionBox{
    height: 30%;  
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 0 ;
    background-color: #FFFFFF;
  }
  .button-r{
    padding-left: 20px
  }
  .table-r{
    height: 590px;
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 15px ;
    margin-top: 10px;
    background-color: #FFFFFF;
  }
  .buttonBox{
    height: 50px;
    padding-left: 5px;
  }

/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
</style>

<style>
  .el-form-item__label{
    padding: 0 0 0 20px ;
  }
  

</style>
