<template>
  <div>
    <el-form :model="list" ref="editForm"  :rules="rules">
    <vxe-modal v-model="val1" title="编辑信息" :width="width" :height="height" show-zoom   :before-hide-method="clear">
      <!-- 点击个人信息的编辑窗口 -->
      <div v-if="personalOrCompany==0" style="text-align: center;">
        <el-form-item label="昵称"  prop="name" >
          <el-input v-model="list.name"   clearable  class="text"  maxlength="10" ></el-input>
        </el-form-item>
        <el-form-item label="性别"   >
          <el-select v-model="list.sex" placeholder="请选择"  class="text" clearable  >
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位"   >
          <el-select v-model="list.position" placeholder="请选择"  class="text" clearable  >
            <el-option
              v-for="item in positionOptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱"  prop="mailbox" >
          <el-input v-model="list.mailbox"   clearable  class="text"></el-input>
        </el-form-item>
        <el-form-item label="主营地区"   >
          <el-select v-model="list.area" placeholder="请选择"  style=" width: 300px;" clearable  :disabled="disabled">
            <el-option
              v-for="item in areaOptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>              
      </div>
      <!-- 点击公司信息的编辑窗口 -->
      <div v-if="personalOrCompany==1" style="margin-top: 10px;">
        <el-form-item label="公司名称"  prop="companyName" class="o">
          <el-input v-model="list.companyName"   clearable  class="text"  maxlength="20" ></el-input>
        </el-form-item>
      </div>

      <div class="centered-content">
          <el-button type="primary" @click="submitInto()"   size="small">确 定</el-button>
          <el-button size="small"   @click="clear()">取 消</el-button>
      </div>
  </vxe-modal>
</el-form>
  </div>
</template>

<script>
export default {
    name:"editInfo",
    data(){
        return{
            val1:false,
            disabled:true,
            width:0,
            height:0,
            personalOrCompany:0,
            list:{},
            sexOptions:[{ label: '女', value: '0' },{ label: '男', value: '1' }],
            positionOptions:[{ label: '二房东', value: '0' },{ label: '中介', value: '1' },{ label: '公寓', value: '2' },{ label: '自由经济人', value: '3' }],
            areaOptions:[{ label: '武汉', value: '0' },{ label: '北京', value: '1' },{ label: '上海', value: '2' }],
            rules: {
              mailbox: [
                { pattern:/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: '邮箱格式有误', trigger: 'blur' }
              ],
              phone: [
                { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
              ],
            },
        }
    },
    mounted(){
      const user = JSON.parse(window.sessionStorage.getItem('user'))
      if(user.identity==1 || user.identity==3 ){
          this.disabled=false
      }
    },
    methods:{
        open(row,state){
          //判断用户点击的是个人信息更改还是企业信息
          if(state==0){
              //个人信息修改
              this.width=500
              this.height=470
              this.personalOrCompany=state
          }else{
              //企业信息修改
            this.width=350
            this.height=300
            this.personalOrCompany=state
          }

          this.val1=true
          this.list=JSON.parse(JSON.stringify(row))

        },
        clear(){
          this.val1=false

          //点击会清除校验结果，并重置表单
          this.$refs.editForm.resetFields()
          this.list={}
        },
        submitInto(){
          sessionStorage.setItem("user",JSON.stringify(this.list))

          this.$refs.editForm.validate((valid) => {
            //判断表单验证是否成功
            if (valid) {
                //发送请求
                this.$axios.post(this.$httpUrl+"/user/edit",this.list)
                .then(res => { 
                  if (res.data.code>=1){
                    //关闭添加或编辑信息界面，将表单清空并刷新表格
                    this.val1=false
                    this.list={}

                    this.$bus.$emit('refreshUser')
                    
                    this.$message({message: res.data.data,type: 'success'});
                  }else{
                    this.$message.error('修改失败');
                  }
                })
            } else {
              //表单验证失败
              console.log('error submit!!');
              return false;
            }
          });

        },
    },
}
</script>

<style scoped>
  .text{
    width: 300px;
    margin-left: 25px;
  }
  /* ::v-deep .o .el-form-item__label {
    padding-left: 15px;
  } */

  .centered-content{
  margin-top: 40px;
  display: flex; /* 创建 Flexbox 布局 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
}

::v-deep .el-form-item__error{
  line-height: 0.5;
  left:60px;
  padding-top: 0px;
}

</style>