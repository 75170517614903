<template>
    <div >
          <vxe-modal v-model="val" title="详细信息" width="1000" height="850" show-zoom  :before-hide-method="clear" :esc-closable="true" class="uu">
              <el-row>
                <el-col :span="5">
                  <div class="iconInfo" style="border-right: 1px solid #93a1a1; ">
                    <div>
                      <img src="../../assets/yusuan.png" width="40" height="40">
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="heading">出租价格({{formatPaymentType}})</span><br>
                      <span class="data">{{list.price}}元/月</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="iconInfo" style="margin-left: 20px;">
                    <div style="margin-top: 5px;">
                      <img src="../../assets/fangwuchushouzuping.png" width="30" height="30">
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="heading">出租类型</span><br>
                      <span class="data">{{formatRentalType}}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="iconInfo" >
                    <div style="margin-top: 5px;">
                      <img src="../../assets/zhaoxiang.png" width="30" height="30">
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="heading">朝向</span><br>
                      <span class="data">{{formatfaceTo}}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="iconInfo" >
                    <div style="margin-top: 5px;">
                      <img src="../../assets/fangwumianji.png" width="30" height="30">
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="heading">面积</span><br>
                      <span class="data">{{list.area}}m²</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="iconInfo" >
                    <div style="margin-top: 5px;">
                      <img src="../../assets/xingbie.png" width="30" height="30">
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="heading">入住性别要求</span><br>
                      <span class="data">{{formatSex}}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                  <div style="margin-top:20px;margin-bottom: 20px;">
                    <span style="font-size: 16px; font-weight: bold;">房屋信息</span>
                    <el-button style="float: right; margin-left: 5px;" type="danger" icon="el-icon-edit" size="mini" plain  @click="Delete()">删除</el-button>
                    <el-button style="float: right;" type="success" icon="el-icon-edit" size="mini" plain  @click="toEdit()">编辑</el-button>       
                  </div>       
              </el-row>
              <el-row style="margin-bottom: 20px;">
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">小区地址</span>
                    <span>{{list.address}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">出租类型</span>
                    <span>{{formatRentalType}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">单元号</span>
                    <span v-if="list.building && list.unit">{{list.building}}栋{{list.unit}}单元</span>
                    <span v-if="!list.building || !list.unit">暂无信息</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">楼层</span>
                    <span v-if="list.level">第{{ list.level }}层</span>
                    <span v-if="!list.level">暂无信息</span>
                  </div>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 20px;">
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">出租价格</span>
                    <span>{{list.price}}元/月<span style="font-size: 11px;">({{formatPaymentType}})</span></span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">户型</span>
                    <span>{{list.chamber}}室{{list.office}}厅{{list.kitchen}}厨{{list.toilet}}卫</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">朝向</span>
                    <span>{{formatfaceTo}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">面积</span>
                    <span>{{list.area}}m²</span>
                  </div>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 20px;">
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">装修</span>
                    <span>{{formatRenovation}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">入住性别要求</span>
                    <span>{{formatSex}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">可入住时间</span>
                    <span>{{formatCheckInTime}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">电梯信息</span>
                    <span>{{ formatLift }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 20px;">
                <el-col :span="6">
                  <div class="info">
                    <span class="heading">租期</span>
                    <span>{{formatLeaseTerm}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <span class="heading">房源物品</span><br>
                    <div  class="label" v-for="(item,index) in this.list.roomTag" :key="index">
                      {{item}}
                    </div>

              </el-row>
              <el-row style="margin-top: 20px; margin-bottom: 20px;">
                  <span class="heading">房源图片</span>
                  <div style="margin-top: 10px; " v-for="(item,index) in img" :key="index">
                    <el-col :span="3" style="margin-right: 10px;">
                      <el-image 
                      style="width: 120px; height: 100px"
                      :src="item" 
                      :preview-src-list="img">
                      </el-image>
                    </el-col>
                  </div>
              </el-row>
              <hr style="opacity:0.3;">
              <el-row>
                  <div style="margin-top:20px;margin-bottom: 20px;">
                    <span style="font-size: 16px; font-weight: bold;">推广信息</span>
                  </div>       
              </el-row>
              <el-row>
                <el-col>
                  <div class="info">
                    <span class="heading">推广标题</span>
                    <span>{{ list.name }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <div class="info">
                    <span class="heading">推广描述</span>
                    <span>{{ list.synopsis }}</span>
                  </div>
                </el-col>
              </el-row>
              <hr style="opacity:0.3;">
              <el-row>
                <span class="heading">创建于 {{list.createTime}}</span>
              </el-row>
              
          </vxe-modal>
          <editInfo ref="editInfo"></editInfo>
    </div>
  </template>
  
  <script>
  import moment from "moment"
  import editInfo from "./editinfo.vue"
  export default {
      // eslint-disable-next-line vue/multi-word-component-names
      name:"info",
      components:{
        editInfo
      },
      data() {
          return {
            val:false,
            img:[],
            list:{},
            imgInfo:[],
          }
      },
      computed:{
        formatRentalType(){
            if(this.list.rentalType===0){
                return '合租'
            }else if(this.list.rentalType===1){
                return '整租'
            }else if(this.list.rentalType===2){
                return '独栋'
            }
            return '暂无信息'
        },
        formatLift(){
            if(this.list.lift===0){
                return '有电梯'
            }else if(this.list.lift===1){
                return '无电梯'
            }
            return '暂无信息'
        },
        formatPaymentType(){
            if(this.list.paymentType===0){
                return '月付'
            }else if(this.list.paymentType===1){
                return '季付'
            }
            return '暂无信息'
        },
        formatRenovation(){
            if(this.list.renovation===0){
                return '精装'
            }else if(this.list.renovation===1){
                return '简装'
            }else if(this.list.rentalType===2){
                return '毛坯'
            }else if(this.list.rentalType===3){
                return '豪华装修'
            }

            return '暂无信息'
        },
        formatSex(){
            if(this.list.sex===0){
                return '不限'
            }else if(this.list.sex===1){
                return '限男生'
            }else if(this.list.sex===2){
                return '限女生'
            }

            return '暂无信息'
        },
        formatfaceTo(){
            if(this.list.renovation===0){
                return '北'
            }else if(this.list.renovation===1){
                return '东'
            }else if(this.list.rentalType===2){
                return '南'
            }else if(this.list.rentalType===3){
                return '西'
            }

            return '暂无信息'
        },
        formatCheckInTime(){

            //this.list.createTime
            let a = moment().format('YYYY-MM-DD')

            if(this.list.checkInTime===a){
                return '随时入住'
            }else if(this.list.checkInTime===null){
                return '暂无信息'
            }
            else{
                return this.list.checkInTime
            }

        },
        formatLeaseTerm(){
            if(this.list.leaseTerm===0){
                return '月'
            }else if(this.list.leaseTerm===1){
                return '季'
            }else if(this.list.leaseTerm===2){
                return '半年'
            }else if(this.list.leaseTerm===3){
                return '整年'
            }

            return '暂无信息'
        },


      },
      mounted:function(){
        this.$bus.$on('getList', this.getList)
      },
      beforeDestroy() {
        // 在该组件弃用删除之后，解绑该事件，减轻$bus的负担
        this.$bus.$off('getList')
      },
      methods:{
        open(row){
            this.val=true
            this.list=JSON.parse(JSON.stringify(row))
            
            this.list.roomTag=this.list.roomTag.split(',')
            if(this.list.roomTag[0]==''){
              this.list.roomTag=[]
            }
            this.getImg()
        },
        clear(){
            this.list={}
            this.img=[]
        },
        toEdit(){
          this.$refs.editInfo.open(this.list,this.imgInfo)
        },
        getImg(){
          this.$axios.get(this.$httpUrl + '/applet/getImg',{params:{id:this.list.id}})
            .then(res=>{
                this.imgInfo=res.data.data
                res.data.data.find(item=>{
                    this.img.push(item.url)
                })
            })

        },
        getList(){
          //
          this.img=[]
          this.$axios.get(this.$httpUrl + '/applet/getRoomListById',{params:{id:this.list.id}})
            .then(res=>{
                this.list=res.data.data
                this.list.roomTag=this.list.roomTag.split(',')
                this.getImg()
            })

        },
        Delete(){
      //删除操作
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
      .then(() => {
          this.$axios.delete(this.$httpUrl+"/applet/delete/"+this.list.id
      )
      .then(res => {    

        if (res.data.code>=1){
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.val=false
          this.clear()
          this.$bus.$emit('getHouse')
        }else{
          this.$message.error('删除失败');
        }
      })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
      }
  }
  </script>
  
  <style scoped>
    .iconInfo{
      display : flex;
      flex-direction:row; 
      margin-top: 10px;
    }
    .info{
      display : flex;
      flex-direction:column;
    }
    .info span{
      margin-bottom: 10px;
    }
  
    .heading{
      /* color: #777;  */
      font-size: 12px;
      opacity:0.7;
    }
    .data{
      font-family: "黑体 bold", sans-serif;
    }
    .label{
      color: #ffa036;
      border: 0.5px solid #ffa036 !important;
      display: inline-block;
      white-space: nowrap;
      padding: 5px;
      border-radius:4px;
      font-size: 12px;
      margin-top: 5px;
      margin-right: 5px;
      
    }
    ::v-deep .vxe-modal--content{
      margin-left: 30px;
    }


      /*滚动条整体部分*/
.uu ::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
/*滚动条的轨道*/
.uu ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
}
/*滚动条里面的小方块，能向上向下移动*/
.uu ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  
}
.uu ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.uu ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
  </style>