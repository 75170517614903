<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style>
#app {
}
html,body{
  width: 100%;
  height: 100%;
  margin: 0;
}

/* 取消input的上下箭头 */
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
 
input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}
 
input[type="number"] {
  -moz-appearance: textfield;
}

</style>
<script>
export default {
  name:'App',
}
</script>