<template>
  <div>
    <el-form :model="list" ref="editForm" :rules="rules" >
    <vxe-modal v-model="val1" :title="addOrEdit===1?'编辑线索':'录入线索'" width="1000" height="670" show-zoom   :before-hide-method="clear">
      <div>

          <div style="display: flex;">
            <el-form-item label="小区地址"  prop="selectedOptions" size="small" style="flex: 1;"> &nbsp;&nbsp;<span style="color: red;">(注:标*为必填项)</span><br>
              <el-select v-model="selectedOptions" placeholder="请选择">
                  <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select> 
            </el-form-item>
            <el-form-item  prop="addressValue" size="small" style="flex: 1;">
                <el-select v-model="addressValue" filterable placeholder="请选择小区" :filter-method="addressSelect"  style="width: 730px; margin-top: 32px;" no-data-text="输入小区以获取数据">
                    <el-option
                        v-for="item in addressOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                        >
                    </el-option>
                </el-select> 
            </el-form-item>
          </div>
            <div>
              <el-row>
                <el-col :span="15">
                  <el-form-item label="标题"  prop="title">
                      <el-input v-model="list.title"   clearable maxlength="15" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="1"><div style="height: 80px"></div></el-col>
                <el-col :span="3">
                    <el-form-item label="期望入住时间"  prop="checkInTime">
                        <el-input v-model.number="list.checkInTime"    clearable  maxlength="2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                  <div class="bottomAlignment">
                    <span>天内</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>户型</span> &nbsp;&nbsp;<span style="color: red;">(注:仅能输入一位数字)</span>
                </el-col>
                <el-col :span="12">
                  <span>客户预算</span>
                </el-col>
            </el-row>
              <el-row>
                <el-col :span="2">
                  <el-form-item label="室" class="label-r" prop="chamber">
                    <el-input v-model="list.chamber"  class="input-r" maxlength="1" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item label="厅" class="label-r" prop="office">
                    <el-input v-model="list.office"  class="input-r" maxlength="1" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item label="厨" class="label-r" prop="kitchen">
                    <el-input v-model="list.kitchen"  class="input-r" maxlength="1" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="卫" class="label-r" prop="toilet">
                    <el-input v-model="list.toilet"  class="input-r" maxlength="1" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item  class="label-ri" prop="budgetMin">
                    <el-input v-model.number="list.budgetMin"   class="input-r" style="width: 60px;" clearable type="number"   maxlength="5"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1">
                  <span>_</span>
                </el-col>
                <el-col :span="3">
                  <el-form-item label="元" class="label-ri" prop="budgetMax">
                    <el-input v-model.number="list.budgetMax"   class="input-r" style="width: 60px;" clearable type="number"  maxlength="5"></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>联系方式</span>&nbsp;&nbsp;<span style="color: red;">(注:必填写手机号或微信其中一项)</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                  <el-form-item label="手机号：" prop="phoneNumber">
                    <el-input v-model="list.phoneNumber"  style="width: 200px;" class="input-r" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="微信号:" prop="wx">
                    <el-input v-model="list.wx"  style="width: 200px;" class="input-r" clearable maxlength="20"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="线索价格:" prop="price">
                    <el-input v-model="list.price"  style="width: 100px;" class="input-r" clearable type="number" maxlength="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="租房方式" prop="rentalType">
                    <el-radio v-model="list.rentalType" :label=0>合租</el-radio>
                    <el-radio v-model="list.rentalType" :label=1>整租</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="线索来源" prop="source">
                    <el-radio v-model="list.source" :label=0>闲鱼</el-radio>
                    <el-radio v-model="list.source" :label=1>高德</el-radio>
                    <el-radio v-model="list.source" :label=2>小红书</el-radio>
                    <el-radio v-model="list.source" :label=3>其他</el-radio>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <el-form-item label="备注" >&nbsp;&nbsp;<span style="color: red;">(注:限85个字)</span><br>
                    <el-input v-model="list.remarks"   clearable  maxlength="85" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>


        
        <div class="centered-content">
          <el-button type="primary" @click="submitInto()"  size="small">确 定</el-button>
          <el-button size="small"   @click="clear()">取 消</el-button>
        </div>
      </div>
  </vxe-modal>
</el-form>
<Test ref="Test"></Test>
  </div>

</template>

<script>
import Test from '../map.vue'

export default {
    name: "addClue",
    components:{
        Test
    },
    data(){
        return{
          val1:false,
          list:{id:null,title:null,rentalType:null,address:null,budgetMin:null,budgetMax:null,paymentType:null,phoneNumber:null,wx:null,remarks:null,chamber:null,office:null,kitchen:null,toilet:null,checkInTime:null,source:null},
          phoneNumberStatus:false,
          wxStatus:false,
          options:[],
          selectedOptions: [],
          paymentOptions:[{ label: '月付', value: '0' },{ label: '季付', value: '1' }],
          addOrEdit:0,
          addressValue:'',
          addressOptions:[],
          user: JSON.parse(sessionStorage.getItem('user')),
          wuhan:[{value: 'wuhan',label: '武汉市'}],
          beijing:[{value:'beijing',label:'北京市'}],
          shanghai:[{value:'shanghai',label:'上海市'}],

          diqu:[{value: '武汉市',label: '武汉市',children:[{value: '江岸区',label: '江岸区'},{value: '江汉区',label: '江汉区'},{value: '硚口区',label: '硚口区'},{value: '汉阳区',label: '汉阳区'},{value: '武昌区',label: '武昌区'},{value: '青山区',label: '青山区'},{value: '洪山区',label: '洪山区'},{value: '东西湖区',label: '东西湖区'},{value: '汉南',label: '汉南区'},{value: '蔡甸区',label: '蔡甸区'},{value: '江夏区',label: '江夏区'},{value: '黄陂区',label: '黄陂区'},{value: '新洲区',label: '新洲区'}]},
              {value:'北京市',label:'北京市',children:[{value: '东城区',label: '东城区'},{value: '西城区',label: '西城区'},{value: '朝阳区',label: '朝阳区'},{value: '丰台区',label: '丰台区'},{value: '石景山区',label: '石景山区'},{value: '海淀区',label: '海淀区'},{value: '门头沟区',label: '门头沟区'},{value: '房山区',label: '房山区'},{value: '通州区',label: '通州区'},{value: '顺义区',label: '顺义区'},{value: '昌平区',label: '昌平区'},{value: '大兴区',label: '大兴区'},{value: '怀柔区',label: '怀柔区'},{value: '平谷区',label: '平谷区'},{value: '密云区',label: '密云区'},{value: '延庆区',label: '延庆区'},]},
              {value:'上海市',label:'上海市',children:[{value: '黄浦区',label: '黄浦区'},{value: '徐汇区',label: '徐汇区'},{value: '长宁区',label: '长宁区'},{value: '静安区',label: '静安区'},{value: '普陀区',label: '普陀区'},{value: '虹口区',label: '虹口区'},{value: '杨浦区',label: '杨浦区'},{value: '闵行区',label: '闵行区'},{value: '宝山区',label: '宝山区'},{value: '嘉定区',label: '嘉定区'},{value: '浦东新区',label: '浦东新区'},{value: '金山区',label: '金山区'},{value: '松江区',label: '松江区'},{value: '青浦区',label: '青浦区'},{value: '奉贤区',label: '奉贤区'},{value: '崇明区',label: '崇明区'},]}
          ],     
          rules: {
            selectedOptions: [
              {validator: (rule, value,callback)=> {
                if (this.selectedOptions.length===0) {
                  callback(new Error("请选择地址"));
                } else {
                  callback();
                }}, trigger: 'change' }
            ],
            addressValue: [
            {validator: (rule, value,callback)=> {
                if (this.addressValue=='' || this.addressValue=='undefined') {
                  callback(new Error("请选择地址"));
                } else {
                  callback();
                }}, trigger: 'blur' }
            ],
            title: [
              { required: true, message: '请输入标题', trigger: 'change'}
            ],
            checkInTime: [
              { required: true, message: '请输入期望入住时间', trigger: 'change' },{ type: 'number',message: '请输入数字'}
            ],
            chamber: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            office: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            kitchen: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            toilet: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            budgetMin: [
              { required: true, message: '请输入预算', trigger: 'change' },{ type: 'number',message: '请输入数字'}
            ],
            budgetMax: [
              { required: true, message: '请输入预算', trigger: 'change' },{ type: 'number',message: '请输入数字'}
            ],
            phoneNumber: [
            {validator: (rule, value,callback)=> {
                if (this.list.wx==null &&  this.list.phoneNumber==null) {
                  callback(new Error("手机号或微信必填一项"));
                }else if((this.list.wx!=null  && this.list.wx!='' && this.list.wx!=' ') && (this.list.phoneNumber!=null && this.list.phoneNumber!=''  && this.list.phoneNumber!=' ' )){
                  callback(new Error("手机号或微信仅能填一项"));
                } else {
                  this.$refs.editForm.clearValidate('phoneNumber')
                  this.$refs.editForm.clearValidate('wx')
                  callback();
                }}, trigger: 'blur' },
              { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur'] }
            ],
            wx: [
            {validator: (rule, value,callback)=> {
                if (this.list.wx==null && this.list.phoneNumber==null) {
                  callback(new Error("手机号或微信必填一项"));
                }else if((this.list.wx!=null  && this.list.wx!='' && this.list.wx!=' ') && (this.list.phoneNumber!=null && this.list.phoneNumber!=''  && this.list.phoneNumber!=' ' )){
                  callback(new Error("手机号或微信仅能填一项"));
                }else {
                  this.$refs.editForm.clearValidate('phoneNumber')
                  this.$refs.editForm.clearValidate('wx')
                  callback();
                }}, trigger: 'blur' },
            ],
            rentalType: [
              { required: true, message: '请选择租房方式', trigger: 'change' }
            ],
            price:[
              { required: true, message: '请输入线索价格', trigger: 'change' }
            ],
            source:[
              { required: true, message: '请选择线索来源', trigger: 'change' }
            ],
          },
        }
    },
    watch: {
        addressValue(newValue) {
            this.addressOptions.forEach(item => {
                if(item.name==newValue){
                    this.list.longitude=item.location.lng
                    this.list.latitude=item.location.lat
                    
                    this.$refs.Test.AllAddress(this.list.longitude,this.list.latitude,2)
                }
            });
        },
        
    },
    mounted(){
      this.$bus.$on('getAddressOptionsClue', (data)=>{
            this.addressOptions=data
      })
      this.$bus.$on('getAllAddressClue', (data)=>{
          console.log('地址信息',data)
          this.list.allAddress=data.regeocode.formattedAddress
          this.list.economize=data.regeocode.addressComponent.province
          this.list.market=data.regeocode.addressComponent.city
          this.list.distinguish=data.regeocode.addressComponent.district
      })
      this.list.checkInTime=7
    },
    beforeDestroy() {
      // 在该组件弃用删除之后，解绑该事件，减轻$bus的负担
      this.$bus.$off('getAddressOptionsClue')
      this.$bus.$off('getAllAddressClue')
    },
    methods:{
      addressSelect(value){
        this.$refs.Test.getPrompt(value,2)
      },
        toAdd(){
          this.val1=true
          this.addressValue=''
          
          switch(this.user.area){
                case 0:
                    this.options=this.wuhan;
                    this.selectedOptions='wuhan'
                    break;
                case 1:
                    this.options=this.beijing;
                    this.selectedOptions='beijing'
                    break;
                case 2:
                    this.options=this.shanghai;
                    this.selectedOptions='shanghai'
                    break;    
                case 3:
                    this.options=this.shenzhen;
                    this.selectedOptions='shenzhen'
                    break;    
                case 4:
                    this.options=this.hangzhou;
                    this.selectedOptions='hangzhou'
                    break;
                case 5:
                    this.options=this.nanjing;
                    this.selectedOptions='nanjing'
                    break;
                case 6:
                    this.options=this.suzhou;
                    this.selectedOptions='suzhou'
                    break;   
            }
        },
        toEdit(row){
          console.log(row)
          this.val1=true
          this.addOrEdit=1
          //将单行数据回显到表单
          this.list=JSON.parse(JSON.stringify(row))
          this.addressValue=this.list.address

          switch(this.user.area){
                case 0:
                    this.options=this.wuhan;
                    this.selectedOptions='wuhan'
                    break;
                case 1:
                    this.options=this.beijing;
                    this.selectedOptions='beijing'
                    break;
                case 2:
                    this.options=this.shanghai;
                    this.selectedOptions='shanghai'
                    break;    
                case 3:
                    this.options=this.shenzhen;
                    this.selectedOptions='shenzhen'
                    break;    
                case 4:
                    this.options=this.hangzhou;
                    this.selectedOptions='hangzhou'
                    break;
                case 5:
                    this.options=this.nanjing;
                    this.selectedOptions='nanjing'
                    break;
                case 6:
                    this.options=this.suzhou;
                    this.selectedOptions='suzhou'
                    break;  
            }
        },        
        clear(){
          this.val1=false

          //点击会清除校验结果，并重置表单
          this.$refs.editForm.resetFields()
          this.list={}
          this.selectedOptions=[]
          this.list.checkInTime=7

        },
        submitInto(){
          this.$refs.editForm.validate((valid) => {
            //判断表单验证是否成功
            if (valid) {

                this.list.address=this.addressValue
                this.list.userId=this.user.id

                //发送请求
                this.$axios.post(this.$httpUrl+"/clue/edit",this.list)
                .then(res => {
                  if (res.data.code>=1){
                    //关闭添加或编辑信息界面，将表单清空并刷新表格
                    this.val1=false
                    this.list={}

                    this.$bus.$emit('refresh')
                    
                    this.$message({message: res.data.data,type: 'success'});
                  }else if(res.data.code==0){
                    this.$message({message: res.data.msg,type: 'error'});
                    return
                  }else{
                    if(this.list.id==null){
                      this.$message.error('添加失败');
                    }else{
                      this.$message.error('修改失败');
                    }
                  }
                })
            } else {
              //表单验证失败
              console.log('error submit!!');
              return false;
            }
          });

        },
       
    }

}
</script>

<style scoped>
::v-deep .el-form-item__label{
  padding: 0 0 0 5px;

}
::v-deep .el-input__inner{

  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;

}
::v-deep .input-r{
  padding-left: 5px;
  padding-right: 5px;
  width: 20px;
  float: left;
}
::v-deep .label-r .el-form-item__label{
  float: right;
  padding-right: 10px;
}

.label-r{
  width: 70px;
  
}
.label-ri{
  width: 100px;
}
::v-deep .label-ri .el-form-item__label{
  float: right;
  padding-right: 0px;
}
::v-deep .input-r .el-input__inner{
 padding-left: 5px;
 padding-right: 5px;
 height: 25px;
} 

::v-deep .label-ri .el-form-item__content .select-r .el-input .el-input__inner{
  height: 25px;
} 

::v-deep .el-radio{
  padding-left: 15px;
}

.centered-content{
  display: flex; /* 创建 Flexbox 布局 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
}

::v-deep .el-form-item__error{
  top: 85%;
}
</style>

<style>
.el-cascader .el-input .el-input__inner{
  height: 32px;
  width: 220px;
  padding-left: 10px;
  padding-right: 0px;
  margin-right:5px;

}

.bottomAlignment{
  height: 10px;
  padding-top: 60px;
  padding-left: 10px;
}

</style>