<template>
  <el-menu
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      style="height: 905px;width: 168px;"
      :default-active="defaultActive"
      :collapse="isCollapse"
      @select="handSelect"
      collapse-transition=""
      router
    >
    <el-menu-item v-for="(item) in menuOptions"  :index="item.path" :key="item.id">
      <i :class="item.icon"></i>
      <span slot="title">{{item.title}}</span>
    </el-menu-item>

  </el-menu>
</template>

<script>
const headers = {
    'Token': sessionStorage.getItem('token'),
};

export default {
    // eslint-disable-next-line vue/multi-word-component-names
  name: "Aside",
  data(){
    return{
      // isCollapse:true
      defaultActive:'',
      menuOptions:[],
      user: JSON.parse(sessionStorage.getItem('user')),
    }
  },
  created() {
    
    //this.defaultActive = sessionStorage.getItem('keyPath') || '/index/house'
  },
  mounted() {
    this.defaultActive = this.$route.path

    this.$bus.$on('toPersonal', this.toPersonal)

    this.getMenu()
	},
  watch:{
    $route:'handSelect'
  },
  props:{
    isCollapse:Boolean
  },
  methods:{
    handSelect() {
      this.defaultActive =this.$route.path 
    },
    getMenu(){
      this.$axios.get(this.$httpUrl + '/menu/getMenu',{params:{id:this.user.id},headers})
          .then(res=>{
            this.menuOptions=res.data.data
          })
    }
  }
}
</script>

<style scoped>

</style>