<template>
    <div>
        <div class="conditionBox">
      <el-form ref="form" :model="orderDto">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="名字"  >
              <el-input v-model="orderDto.name" placeholder="姓名" class="input-r" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号"  >
              <el-input v-model="orderDto.phone" placeholder="手机号" class="input-r" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单类型"  >
            <el-select v-model="orderDto.type" placeholder="请选择"  clearable  class="select-r">
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <div class="button-r">
        <el-button type="primary" @click="getOrder()"  icon="el-icon-search" size="small" plain>查询</el-button>
        <el-button type="info"    @click="resetting()" icon="el-icon-refresh" size="small"  plain>重置</el-button>
      </div>
    </div>

    <div class="table-r">
        <div class="buttonBox">
            <el-button style="font-size: 13px;"  type="success" @click="orderStatus(1)" icon="el-icon-search" size="small" plain>查询已支付</el-button>
            <el-button style="font-size: 13px;"  type="danger" @click="orderStatus(0)" icon="el-icon-search" size="small" plain>查询未支付</el-button>
        </div>
        <vxe-table
          border
          show-header-overflow
          show-overflow
          :row-config="{isHover: true,height: 40,isCurrent: true,}"
          height="550px"
          :data="list"
          :loading-config="{icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...'}"
          stripe
          round
          @current-change="currentChangeEvent"
          :seq-config="{startIndex: (currentPage - 1) * pageSize}"
          ref="xTable"
          align="center"
          class="mytable-scrollbar"
          style="width: 100%; margin-top: 20px; margin-left: 10px;"
        >
          <vxe-column type="seq" title="序号" width="60"></vxe-column>
          <vxe-column field="name" title="名字"  ></vxe-column>
          <vxe-column field="phoneNumber" title="手机号"  ></vxe-column>
          <vxe-column field="type" title="订单类型"   :formatter="formatterType"></vxe-column>
          <vxe-column field="orderNumber" title="订单号"  width="230"> </vxe-column>
          <vxe-column field="commodityInformation" title="商品信息"  > </vxe-column>
          <vxe-column field="price" title="价格"  :formatter="formatterPrice"> </vxe-column>
          <vxe-column field="orderStatus" title="订单状态"  :formatter="formatterOrderStatus"></vxe-column>
          <vxe-column field="createTime" title="创建时间"   ></vxe-column>
          <vxe-column field="paymentTime" title="付款时间" ></vxe-column>
        </vxe-table>
        <div style="float: right;">
            <vxe-pager
            background
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            :total="totalResult"
            :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
            :page-sizes="pageSizes"
            @page-change="handleCurrentChange"
            align="left"
            size="mini" 

            >
            </vxe-pager>
        </div>
    </div>





    </div>
</template>

<script>
export default {
    data(){
        return{
            orderDto:{},
            currentPage: 1,     // 当前页
            totalResult: 0,     // 总条数，根据接口获取数据长度(注意：这里不能为空)
            pageSizes: [10, 20, 30, 50],    // 个数选择器（可修改）
            pageSize: 20,       // 默认每页显示的条数（可修改）
            currentRow: {},  //点击表格获取单格表的数据
            list:[],
            typeOptions:[{ label: '开通端口', value: '0' },{ label: '充值线索币', value: '1' }]
        }
    },
    mounted(){
        this.getOrder()
    },
    methods:{
        getOrder(){
            this.$axios.get(this.$httpUrl+'/order/getOrder',
            {
              params:{page:this.currentPage,  pageSize:this.pageSize, name:this.orderDto.name, phone:this.orderDto.phone,orderStatus:this.orderDto.orderStatus,type:this.orderDto.type}
            }
            )
            .then(res=>{

                this.list=res.data.data.list
                this.totalResult=res.data.data.total


                //默认高亮表格第一行,并获取第一行数据
                if(this.list!=null){
                    this.$refs.xTable.setCurrentRow(this.list[0])
                }

                this.currentRow=this.list[0]
            })

        },
        resetting(){
            //重置按钮
            this.orderDto={}
            this.getOrder()
        },
        currentChangeEvent({row}){
            //点击获取当前行数据
            this.currentRow = row;
        },
        handleCurrentChange({currentPage,pageSize}){
            //分页控制
            this.currentPage=currentPage
            this.pageSize=pageSize

            this.getOrder()
        },
        orderStatus(e){
            this.orderDto.orderStatus=e
            this.currentPage=1
            this.getOrder()
        },
        formatterType({ cellValue }){
            if(cellValue==0){
                return '开通端口'
            }else if(cellValue==1){
                return '充值线索币'
            }
        },
        formatterPrice({ cellValue }){
            return cellValue+'元'
        },
        formatterOrderStatus({ cellValue }){
            if(cellValue==0){
                return '未支付'
            }else if(cellValue==1){
                return '已支付'
            }
        },

    }
}
</script>

<style>
  .conditionBox{
        height: 30%;  
        border-width: 2px; 
        border-style: solid; 
        border-color: rgb(214, 210, 210);
        border-radius: 20px;
        padding: 15px 25px 15px 0 ;
        background-color: #FFFFFF;
  }
  .input-r{
    width: 60%;
    float: right;
    
  }
  .button-r{
    padding-left: 20px
  }
  .table-r{
    height: 630px;
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 15px ;
    margin-top: 10px;
    background-color: #FFFFFF;
  }
  .buttonBox{
    height: 30px;
    padding-left: 10px;
  }
  .select-r {
    width: 60%;
    float: right;
  }
  /*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}

</style>