import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import Index from '../components/Index'
import Users from '../components/Users'
import Clue from '../components/clue/clueManagement'
import Personal from '../components/Personal/Personal'
import Order from '../components/order/order'
import Property from '../components/property/propertyManagement'
import Test from '../components/map'
import Test1 from '../components/test2'
Vue.use(ElementUI);



const routes =[
    {
        path:'/',
        name:'login',
        component:() => import('../components/Login')
    },
    {
        path:'/Index',
        name:'index',
        component:Index,
        redirect:'index/house',
        children:[
            {path: 'House',component:Property},
            {path: 'Users',component: Users},
            {path: 'Clue',component: Clue},
            {path: 'Personal',component:Personal},
            {path: 'Order',component:Order},
            {path: 'Test',component:Test},
            {path: 'Teste',component:Test1}
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }

]


const router = new VueRouter({
    mode:'hash',
    routes
})

router.beforeEach((to, from, next) => {

    const user  = JSON.parse(sessionStorage.getItem('user'))
    const token = sessionStorage.getItem('token')







    // if(to.path==='/index/users' || to.path==='/index/order' ){
    //     if( user.identity==0 || user.identity==3){
    //         next('/')
    //     }
    // }


    // if(to.path==='/index/clue' || to.path==='/index/order'){
    //     if( user.identity==0 || user.identity==2){
    //         next('/')
    //     }
    // }

    if (to.name!== 'login' && token==null) {
        Vue.prototype.$message.warning('身份已过期！请重新登录')
        next({ name: 'login' });
        return
    } else {
        next();
    }    
    if (to.path==='/index/house' || to.path ==='/index/personal') {
        // 普通用户、管理员、销售、运营都有房源管理权限
        if (user.identity === 0 || user.identity === 1 || user.identity === 2 || user.identity === 3 ) {
            next();
        } else {
            Vue.prototype.$message.warning('身份已过期！请重新登录')
            next('/');
        }
    } else if (to.path==='/index/users') {
        // 管理员和销售有用户管理权限
        if (user.identity === 1 || user.identity === 2 && token!=null) {
            next();
        } else {
            Vue.prototype.$message.warning('身份已过期！请重新登录')
            next('/');
        }
    } else if (to.path==='/index/clue') {
        // 管理员和运营有线索管理权限
        if (user.identity === 1 || user.identity === 3 && token!=null) {
            next();
        } else {
            Vue.prototype.$message.warning('身份已过期！请重新登录')
            next('/');
        }
    }else if (to.path==='/index/order') {
        // 管理员和运营有线索管理权限
        if (user.identity === 1 && token!=null) {
            next();
        } else {
            Vue.prototype.$message.warning('身份已过期！请重新登录')
            next('/');
        }
    } else {
        // 其他路由直接放行
        next();
    }
    
})



export default router;