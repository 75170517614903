import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/globle.css'
import axios from "axios";
import VueRouter from 'vue-router';
import router from "./router";
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'


Vue.prototype.$axios=axios
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VXETable);


axios.defaults.withCredentials=true
//axios发出的请求都默认带token
//请求拦截器
axios.interceptors.request.use(config=>{
  config.headers.token = sessionStorage.getItem('token')
  return config
})

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
      // 处理响应数据
      return response;
  },
  (error) => {
      if (error.response) {
        const { status } = error.response;
        if (status === 401 || status === 402) {
            Vue.prototype.$message.warning('身份已过期！请重新登录')
            // 清除本地存储的 token
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')
            // 跳转到登录页
            router.push('/');
        }
    }
    return Promise.reject(error);
  }
);


//防止sessionStorage修改
window.addEventListener('storage', function (e) {
  sessionStorage.setItem(e.key, e.oldValue)
})


Vue.prototype.$httpUrl=`${window.location.protocol}//${window.location.host}`
//Vue.prototype.$httpUrl='http://localhost:8082'
Vue.config.productionTip = false




new Vue({
  router,
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线，$bus就是当前应用的vm
  },
  render: h => h(App)
}).$mount('#app')
